import React from 'react';
import { Avatar, Box, LinearProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../../../css/images/header/header-logo.svg';

// Static styles extracted using `styled`
const StyledPaper = styled(Paper)({
  backgroundColor: '#1f66ff',
  height: '100vh',
  borderRadius: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledAvatar = styled(Avatar)({
  width: '10rem',
  height: '10rem',
  '& img': {
    objectFit: 'none',
  },
});

const LoaderComponent = React.memo(() => {
  return (
    <StyledPaper>
      <Box>
        <StyledAvatar variant="square" src={logo} />
        <LinearProgress />
      </Box>
    </StyledPaper>
  );
});

export default LoaderComponent;
