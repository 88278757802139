import React, { Suspense, lazy, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import LoaderComponent from './Components/careers/LoaderComponent/LoaderComponent';

// Lazy load the App component
const App = lazy(() => import('./App'));

// Function to render the fallback component

const RootComponent = () => {
  const renderLoader = useCallback(() => <LoaderComponent />, []);

  return (
    <React.StrictMode>
      <Suspense fallback={renderLoader()}>
        <App />
      </Suspense>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
